<template>
  <v-container class="my-10 mb-8 pb-8 dcl-form">
    <v-dialog v-model="submitDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ resMessage.title || 'Your form has been submitted' }}</v-card-title>
        <v-card-text>
          <p>{{ resMessage.message || 'You will get a copy of your form in the email you provided' }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="submitLoader" max-width="500px">
      <v-card>
        <v-card-title>Your form is being submitted</v-card-title>
        <v-card-text>
          <p>Your form is being processed. This might take some time...</p>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-snackbar v-model="snackBar.show" :timeout="3000" :color="snackBar.color" elevation="24" location="bottom right">
      <span class="snackbar__message">{{ snackBar.text }}</span>
    </v-snackbar>

    <div class="dcl__header">
      <h2><v-btn class="me-2" icon href="javascript:history.back()"><v-icon>mdi-chevron-left</v-icon></v-btn> Close
        DEMAT Account
        Form</h2>

      <v-divider class="my-8"> </v-divider>
    </div>

    <div class="form-container">
      <v-form v-model="valid">
        <v-container>
          <!-- ? Grand Father's Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Grand Father's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. George Doe" density="compact"
                  v-model="clientInfo.grandfatherName" :rules="clientInfoRules.grandfatherNameRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Father's Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Father's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Jonathan Doe" density="compact"
                  v-model="clientInfo.fatherName" :rules="clientInfoRules.fatherNameRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Name -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Client's Name
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Joseph Doe" density="compact" v-model="clientInfo.name"
                  :rules="clientInfoRules.nameRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Email -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Client's Email (You will receive a copy of the form here)
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Joseph Doe" density="compact"
                  v-model="clientInfo.email" :rules="stringRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Account No -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Account Number
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. 1XXXXXXXXXXXX" density="compact"
                  v-model="clientInfo.accountNo" :rules="clientInfoRules.accountNoRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Address -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <div class="mb-2">
                  <span class="grey--text text--darken-3 inputLabel">
                    Address
                  </span>
                </div>

                <v-text-field variant="outlined" placeholder="e.g. Kupondole, Lalitpur" density="compact"
                  v-model="clientInfo.address" :rules="clientInfoRules.addressRules"></v-text-field>
              </section>
            </v-col>
          </v-row>
          <!-- ? Submit -->
          <v-row dense justify="center">
            <v-col cols="12" sm="10" md="10">
              <section class="pb-2">
                <v-btn elevation="0" color="success" style="text-transform: capitalize; letter-spacing: 0px;"
                  @click="submit">Submit
                  <v-icon right>mdi-content-save</v-icon></v-btn>
              </section>
            </v-col>
          </v-row>

        </v-container>

      </v-form>
    </div>
  </v-container>
</template>

<script>
import DCLService from "../services/API/DCLService"
export default {
  name: "DCL",
  data() {
    return {
      valid: false,
      submitDialog: false,
      submitLoader: false,
      snackBar: {
        show: false,
        color: 'success',
      },
      resMessage: {
        title: null,
        message: null
      },
      clientInfo: {
        grandfatherName: '',
        fatherName: '',
        name: '',
        accountNo: '',
        address: '',
        email: '',
      },
      clientInfoRules: {
        grandfatherNameRules: [
          v => !!v || "Grand Father's name is required",
        ],
        fatherNameRules: [
          v => !!v || "Father's name is required",
        ],
        nameRules: [
          v => !!v || "Name is required",
        ],
        accountNoRules: [
          v => !!v || "Account Number is required",
        ],
        addressRules: [
          v => !!v || "Address is required",
        ],
      }
    }
  },
  methods: {
    async submit() {
      this.submitLoader = true;
      await DCLService.FIllDCLForm(this.clientInfo).then((response) => {
        if (response.status != 200) {
          this.resMessage.title = "Could Not Submit Form"
          this.resMessage.message = "There was an error while submitting this form"
        }

        this.submitLoader = false;
        this.submitDialog = true;
      })
    }
  }
}
</script>

<style scoped>
.form-container {
  max-width: 800px;
  margin-inline: auto;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0.4rem;
  padding-block: 2rem;
}

.inputLabel {
  font-weight: 600 !important;
  font-size: 0.9rem;
}

.inputTitle {
  font-weight: 600 !important;
  font-size: 1.2rem;
}

.dcl__header {
  max-width: 800px;
  margin-inline: auto;
}
</style>
