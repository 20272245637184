import APIManager from './APIManager';

// ? DEMAT Account Closing Letter
const FIllDCLForm = async (queryObj) => {
    const formResponse = APIManager.axios
        .post(`/api/v1/public/demat-closing-letter`, queryObj, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((response) => {
            return response;
        });

    return formResponse;
};

export default {
    FIllDCLForm,
};
